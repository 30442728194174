<template>
  <div class="Index">
    <img class="first-img" src="../assets/first_screen.png">
  </div>
</template>
<script>
import { login } from "@/api/index";

export default {
  name: "Index",
  mounted() {
    this.getLogin();
  },

  methods: {
    toHome() {
      this.$router.push({ path: "/Home" });
    },
    getLogin() {
      let obj = this.getParams(window.location.href);
      // 获取对象里面的code值
      let pcCode = obj.code;
      pcCode = pcCode.slice(0, pcCode.length - 2);
      const params = {
        code: pcCode,
      };
      login(params).then((res) => {
        let { token } = res.resultData;
        localStorage.setItem("token", token);
        setTimeout(()=> {
          this.toHome()
        },1500)
      });
    },
    // 截取字符串 封装函数
    getParams(url) {
      // console.log("url",url);
      let obj = {};
      let index = url.indexOf("?"); // 看url有没有参数
      // console.log("index",index);
      let params = url.substr(index + 1); // 截取url参数部分 id = 1 & type = 2
      if (index != -1) {
        // 有参数时
        let parr = params.split("&"); // 将参数分割成数组 ["id = 1 ", " type = 2"]
        for (let i of parr) {
          // 遍历数组
          let arr = i.split("="); // 1） i id = 1   arr = [id, 1]  2）i type = 2  arr = [type, 2]
          obj[arr[0]] = arr[1]; // obj[arr[0]] = id, obj.id = 1   obj[arr[0]] = type, obj.type = 2
        }
      }
      return obj;
    },
  },
};
</script>
  
<style lang="scss">
@import "../styles/vars";

.Index {
  height: 100vh;
  background: #fff;
  .first-img{
    width: 100vw;
    height: 100vh;
  }
}
</style>